import './Footer.css';

const Footer: React.FC = () => {
  return (
    <div className="footer">
      <div>© biiird games LLC, {new Date().getFullYear()}</div>
      <div>
        &#169; Vanilla Extract font by{' '}
        <a
          href="https://sharasfonts.wordpress.com/"
          target="_blank"
          rel="nofollow noreferrer"
        >
          sharasfonts
        </a>
      </div>
    </div>
  );
};

export default Footer;
