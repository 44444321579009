import { useContext } from 'react';

import { Page } from '../../../types';

import catMinerTitleScreen from '../../../media/cat-miner/cat-miner-logo-transparent.png';
import notToScaleCover from '../../../media/not-to-scale/not-to-scale-cover.jpg';
import ActivePageContext from '../../../ActivePageContext';
import CatMinerPage from './cat-miner/CatMiner';
import NotToScalePage from './not-to-scale/NotToScale';
import './Games.css';

const Games: React.FC = () => {
  const { setActivePage } = useContext(ActivePageContext);

  return (
    <div className="games">
      <h2 className="title">My Games</h2>
      <div
        className="background cat-miner game"
        onClick={setActivePage(CatMinerPage)}
      >
        <img src={catMinerTitleScreen} alt="Cat Miner Cover" />
      </div>
      <div
        className="background not-to-scale game"
        onClick={setActivePage(NotToScalePage)}
      >
        <img src={notToScaleCover} alt="Not to Scale Cover" />
      </div>
    </div>
  );
};

const GamesPage: Page = {
  FC: Games,
  frameClass: 'home',
  name: 'Games',
  url: 'games',
};

export default GamesPage;
