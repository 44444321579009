import React from 'react';
import { Page } from '../../../../types';

import catMinerTitleScreen from '../../../../media/cat-miner/cat-miner-logo-transparent.png';
import blastingLoop from '../../../../media/cat-miner/blasting-loop.mp4';
import miningLoop from '../../../../media/cat-miner/mining-loop.mp4';
import environments from '../../../../media/cat-miner/environments.jpg';
import moonrise from '../../../../media/cat-miner/moonrise.jpg';
import desertTwilight from '../../../../media/cat-miner/desert-twilight.jpg';
import rainbowMountains from '../../../../media/cat-miner/rainbow-mountains.jpg';
import night from '../../../../media/cat-miner/night.jpg';
import mining1 from '../../../../media/cat-miner/mining-1.jpg';
import mining2 from '../../../../media/cat-miner/mining-2.jpg';
import mining3 from '../../../../media/cat-miner/mining-3.jpg';
import teleportation from '../../../../media/cat-miner/teleportation.mp4';
import Carousel from '../../../carousel/Carousel';
import SmartVideo from '../../../smart-video/SmartVideo';

import { discord } from '../../contact/Contact';

const CAT_MINER_STRING = 'cat-miner';

const links = (
  <React.Fragment>
    <a
      href="https://testflight.apple.com/join/kScThNV1"
      target="_blank"
      rel="noreferrer"
    >
      <button>
        <h3>iPhone download</h3>
      </button>
    </a>
    <a
      href="https://play.google.com/store/apps/details?id=com.biiirdgames.CatMiner"
      target="_blank"
      rel="noreferrer"
    >
      <button>
        <h3>Android Download</h3>
      </button>
    </a>
  </React.Fragment>
);

const CatMiner: React.FC = () => {
  return (
    <div className={`game-page ${CAT_MINER_STRING}`}>
      <section>
        <img className="logo" src={catMinerTitleScreen} alt="Game Logo" />

        <p>Cat Miner is in Open Beta now! Join the testing below:</p>
        {links}
      </section>

      <section>
        <iframe
          className="youtube-video"
          src="https://www.youtube.com/embed/1dE3uaBuMds?si=_UC-gQ9J2fCi4fER"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
      </section>

      <section>
        <p>
          <strong>Cat Miner</strong> is a simulation adventure where you choose
          your style to get through dirt and rock, unearthing increasingly more
          valuable ores and gems and finding new adventures.
        </p>

        <SmartVideo src={miningLoop} />
      </section>

      <section>
        <p>
          Claw your problems away! Or if you're more into explosions, blow 'em
          sky high!
        </p>
        <SmartVideo src={blastingLoop} />
      </section>

      <section>
        <p>
          Discover new horizons as you keep moving through progressively deeper
          and more colorful mining locations.
        </p>

        <Carousel>
          <img
            src={environments}
            alt="Screenshot showcasing multiple game environments"
          />
          <img src={moonrise} alt="Screenshot showcasing game environments" />
          <img
            src={desertTwilight}
            alt="Screenshot showcasing game environments"
          />
          <img
            src={rainbowMountains}
            alt="Screenshot showcasing game environments"
          />
          <img src={night} alt="Screenshot showcasing game environments" />
        </Carousel>
      </section>

      <section>
        <p>Build your own mining operation...</p>

        <img src={mining1} alt="Screenshot showcasing mining operations" />
        <img src={mining2} alt="Screenshot showcasing mining operations" />
        <img src={mining3} alt="Screenshot showcasing mining operations" />
      </section>

      <section>
        <p>
          ...and use <s>magic</s> <em>teleporter science</em> to get around!
        </p>
        <SmartVideo src={teleportation} />
      </section>

      <section>
        <p>
          Ready to jump into the action? Join the <strong>Cat Miner</strong>{' '}
          Open Beta now!
        </p>
        {links}
        <p>Come join the biiird games Discord server and let's have a chat!</p>
        <a href="/" onClick={discord}>
          <button>
            <h3>Join Discord server</h3>
          </button>
        </a>{' '}
      </section>

      <section id="privacy">
        <a href="/cat-miner-eula.pdf" target="_blank">
          <p>End User License Agreement</p>
        </a>
        <a href="/cat-miner-privacy.pdf" target="_blank">
          <p>Privacy Policy</p>
        </a>{' '}
      </section>
    </div>
  );
};

const CatMinerPage: Page = {
  FC: CatMiner,
  frameClass: CAT_MINER_STRING,
  name: 'Cat Miner',
  url: CAT_MINER_STRING,
};

export default CatMinerPage;
